import React from 'react'
import { Link } from 'gatsby'

import { ExploreMoreStyles } from './GlobalComponents.styles';

const ExploreMore = ({ link, text }) => {
    return (
        <ExploreMoreStyles>
            <Link to={link}>
                <span className='text'>
                    {text}
                </span>
                <span className="arrow">
                    <svg xmlns="http://www.w3.org/2000/svg" width="8.414" height="15.067" viewBox="0 0 8.414 15.067">
                        <path id="arrow" d="M12.239,0,6.119,6,0,0" transform="translate(1.414 13.653) rotate(-90)" fill="none" stroke="#477b11" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2" />
                    </svg>
                </span>
            </Link>
        </ExploreMoreStyles>
    )
}

export default ExploreMore