import React, { useState, useContext } from "react"
import Hero from "../components/Hero"
import PropertType from "../components/PropertyType"
import Seo from "../components/seo"
import { useAuthContext } from "../hooks/useAuthContext"
import { graphql } from "gatsby"
import { StateContext } from "../helpers/StateProvider"

const PropertyType = props => {
  const { authIsReady } = useAuthContext()
  let inverted = true
  const { language, setLanguage } = useContext(StateContext)

  return (
    <>
      {props.data.allPropertyType.edges && props.data.allPropertyType.edges && (
        <>
          <Seo title="Property Type" />
          <Hero
            arabic={language}
            title={
              language == false
                ? props.data.allPage.edges[0].node.title
                : props.data.allPage.edges[0].node.title_ar
            }
            subTitle={
              language == false
                ? props.data.allPage.edges[0].node.description
                : props.data.allPage.edges[0].node.description_ar
            }
          />
          {/* <Widgets widgetsData='property-type'/>  */}

          {props.data.allPropertyType.edges.map(type => {
            inverted = !inverted
            return (
              <PropertType
                inverted={inverted}
                key={type.id}
                type={type}
                arabic={language}
              />
            )
          })}
        </>
      )}
    </>
  )
}

export const query = graphql`
  {
    allUsers {
      edges {
        node {
          first_name
          id
          arabic_language
        }
      }
    }
    allPage(filter: { name: { eq: "property-type" } }) {
      edges {
        node {
          name
          title
          title_ar
          id
          description
          description_ar
          widgets {
            name
            type
          }
        }
      }
    }
    allPropertyType {
      edges {
        node {
          description
          description_ar
          id
          image
          link
          title
          title_ar
          value
          subcategories {
            label
            label_ar
            value
          }
          localImage {
            childImageSharp {
              fixed(width: 1000) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
    top: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "top" } }
    ) {
      edges {
        node {
          classname
          id
          label_ar

          label
          link
          type
        }
      }
    }
    followus: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "followus" } }
    ) {
      edges {
        node {
          classname
          id

          label
          link
          type
        }
      }
    }
    browse: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "browse" } }
    ) {
      edges {
        node {
          classname
          id
          label_ar

          label
          link
          type
        }
      }
    }
    explore: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "explore" } }
    ) {
      edges {
        node {
          classname
          id
          label_ar

          label
          link
          type
        }
      }
    }
    ourservices: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "ourservices" } }
    ) {
      edges {
        node {
          classname
          id
          label_ar

          label
          link
          type
        }
      }
    }
    topcategories: allNavebar(
      sort: { order: ASC, fields: position }
      filter: { type: { eq: "topcategories" } }
    ) {
      edges {
        node {
          classname
          id
          label_ar

          label
          link
          type
        }
      }
    }
    widget7: widget(name: { eq: "looking-for-exposure" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
      localImage {
        childImageSharp {
          fixed(width: 1140, height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    widget8: widget(name: { eq: "who-we-are" }) {
      title_ar
      link_text_ar
      description_ar
      id
      image

      link
      link_text
      name
      title
      type
      description
      details {
        description
        image

        link
        svg
        title
      }
    }
  }
`
export default PropertyType
