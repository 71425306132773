import styled from "styled-components"

export const Container = styled.div`
  padding: 50px 0;
`

export const PropertyTypeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  flex-direction: ${({ inverted }) => (inverted ? "row-reverse" : "row")};

  .property-img {
    width: 100% !important;
    border-radius: 15px;
  }

  @media (max-width: 992px) {
    flex-direction: column;
    gap: 30px;
  }
`

export const TextContainer = styled.div`
  padding: 0 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  h3 {
    color: var(--darkText);
  }

  p {
    color: var(--lightText);
  }

  .link-container {
    width: 100%;
    padding: 30px 0;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
  }

  @media (max-width: 992px) {
    align-items: center;
  }
`
