import React from "react"
import Img from 'gatsby-image';

import {
  Container,
  PropertyTypeContainer,
  TextContainer,
} from "./PropertyType.styles"
import ExploreMore from "../GlobalComponents/ExploreMore"


import House from "../../assets/icons/house.svg"

const PropertType = ({ inverted, type,arabic }) => {
  const data = type.node
  return (
    <Container>
      <div className="container">
        <PropertyTypeContainer inverted={inverted}>
        {/* <Img
            fixed={data.localImage.childImageSharp.fixed}
            alt="property-type"
            placeholder="tracedSVG"
            className="property-img"
            layout="fullWidth"
          />       */}
          <img src={type.node.image}></img>
          <TextContainer className={arabic==false ? '':'text-end'}>
            <img src={House} alt="house logo" />
            <h3 className={arabic==false ? '':'text-end'}>{arabic==false?data.title:data.title_ar}</h3>
            <p>{arabic==false? data.description: data.description_ar}</p>
            <div className="link-container">
              <ExploreMore text={arabic==false? "FIND A PROPERTY" :"ابحث عن عقار"}link="/find-a-property" />
            </div>
          </TextContainer>
        </PropertyTypeContainer>
      </div>
    </Container>
  )
}

export default PropertType
