import React from "react"
import { HeroContainer } from "./Hero.styles"

const Hero = ({ title, subTitle, arabic }) => {
  return (
    <HeroContainer>
      <div className={arabic == false ? "container" : "container text-end"}>
        <h1>{title}</h1>
        <p className={arabic == false ? "" : "w-100"}>{subTitle}</p>
      </div>
    </HeroContainer>
  )
}

export default Hero
